<script setup lang="ts">
import { useIntervalFn } from "@vueuse/core"
import { computed, ref, toRefs } from "vue"

const emit = defineEmits(["done"])

type Props = {
    name?: string
    type: "focus" | "rest"
    start: Date
    end: Date
}

const props = defineProps<Props>()

const { start, end } = toRefs(props)

const getSeconds = () => Math.floor((end.value.getTime() - new Date().getTime()) / 1000)

const remainingSeconds = ref(getSeconds())

const totalSeconds = computed(() => Math.floor(end.value.getTime() - start.value.getTime()) / 1000)

const minutes = computed(() => Math.floor(remainingSeconds.value / 60))

const seconds = computed(() => (remainingSeconds.value % 60).toString().padStart(2, "0"))

const progress = computed(() => (remainingSeconds.value / totalSeconds.value) * 100)

useIntervalFn(() => {
    remainingSeconds.value = getSeconds()

    if (remainingSeconds.value <= 0) {
        emit("done")
    }
}, 250)
</script>

<template>
    <div class="[ highlight-white/50 leading-4 bg-black/50 backdrop-blur-md text-white px-6 py-5 rounded-xl w-[20rem] ]">
        <div class="[ text-xs text-white opacity-70 font-medium flex gap-2 ]">
            <span class="[ capitalize ]">{{ type }}</span>
            <template v-if="name">
                <span>&rarr;</span>
                <span>{{ name }}</span>
            </template>
        </div>
        <div class="[ text-2xl font-semibold tracking-tighter proportional-nums ]">{{ minutes }}:{{ seconds }}</div>

        <div class="[ h-1.5 w-full bg-white/20 rounded-full flex justify-end mt-2 ]">
            <div
                class="[ h-1.5 bg-white/40 rounded-full transition ]"
                :style="{
                    width: `${progress}%`,
                }"
            ></div>
        </div>
    </div>
</template>

<style scoped></style>
