<script setup lang="ts">
import { useIntervalFn } from "@vueuse/core"
import { computed, ref, toRefs } from "vue"
import Timer from "./Timer.vue"

type Props = {
    timer: any
}

const emit = defineEmits(["done"])

const props = defineProps<Props>()

const { timer } = toRefs(props)

const getType = () => {
    if (new Date() > new Date(timer.value.focus.end)) {
        return "rest"
    } else {
        return "focus"
    }
}

const type = ref<"focus" | "rest">(getType())

const clock = computed(() => {
    if (type.value === "rest") {
        return timer.value.rest
    } else {
        return timer.value.focus
    }
})

const start = computed(() => new Date(clock.value.start))
const end = computed(() => new Date(clock.value.end))

useIntervalFn(() => {
    type.value = getType()
}, 250)

function handleTimerDone() {
    if (type.value === "rest") {
        emit("done", timer.value)
    }
}
</script>

<template>
    <Timer :name="timer.name" :type="type" :start="start" :end="end" @done="handleTimerDone" />
</template>

<style scoped></style>
