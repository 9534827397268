<script setup lang="ts">
import { useForm } from "@inertiajs/vue3"
import { SubmitButton } from "@primitives/button"
import { ContentContainer } from "@primitives/content-container"
import { Field } from "@primitives/field"
import { Input } from "@primitives/input"
import { Page, PageHeader, PageHeading } from "@primitives/page"

type Props = {}

defineProps<Props>()

const form = useForm({
    email: "",
    password: "",
})

function submit() {
    form.post("/signin")
}
</script>

<template>
    <Page title="Sign in to Thing">
        <ContentContainer wrap="dialog">
            <PageHeader>
                <PageHeading>Sign in to Thing</PageHeading>
            </PageHeader>

            <form action="" @submit.prevent="submit" class="[ grid gap-6 mt-4 ]">
                <Field id="email" label="Email address" :error="form.errors.email">
                    <Input type="email" name="email" v-model="form.email" />
                </Field>

                <Field id="password" label="Password" :error="form.errors.password">
                    <Input type="password" name="password" v-model="form.password" />
                </Field>

                <div class="[ flex justify-end mt-2 ]">
                    <SubmitButton :processing="form.processing">Sign in</SubmitButton>
                </div>
            </form>
        </ContentContainer>
    </Page>
</template>

<style scoped></style>
