<script setup lang="ts">
import { useIntervalFn } from "@vueuse/core"
import axios from "axios"
import { router } from "@inertiajs/vue3"
import { nextTick, onMounted, ref, toRefs, watch } from "vue"
import PomodoroTimer from "$app/components/PomodoroTimer.vue"

type Timer = {
    minutes: number
    start: string
    end: string
}

type Props = {
    echoChannel: string
    timers: Timer[]
}

const props = defineProps<Props>()

const { echoChannel } = props

const mutableTimers = ref(props.timers)

watch(
    () => props.timers,
    (timers) => {
        mutableTimers.value = timers
    }
)

const EVERY_SIXTY_MINUTES = 1000 * 60 * 60

const images = ref([])

const $blurredImage = ref()

const randomImage = () => images.value[Math.floor(Math.random() * images.value.length)]

const now = () =>
    Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
    }).format()

const image = ref()

const time = ref(now())

onMounted(() => {
    fetchImages().then(() => {
        setRandomImage()
    })

    window.Echo.private(echoChannel).listenToAll(() => {
        router.reload({
            only: ["timers"],
        })
    })
})

async function fetchImages() {
    images.value = await axios.get("/api/photos").then((response) => response.data)
}

function setRandomImage() {
    image.value = randomImage()

    nextTick(() => {
        $blurredImage.value.getAnimations().forEach((animation) => {
            animation.cancel()
            animation.play()
        })
    })
}

const EVERY_TEN_SECONDS = 1000 * 10

useIntervalFn(() => setRandomImage(), EVERY_TEN_SECONDS)

useIntervalFn(() => (time.value = now()), 300)

useIntervalFn(() => fetchImages(), EVERY_SIXTY_MINUTES)

function handlePomodoroTimerDone(timer) {
    mutableTimers.value = mutableTimers.value.filter(function ({ id }) {
        return timer.id !== id
    })
}
</script>

<template>
    <div class="[ h-screen w-screen relative antialiased overflow-hidden ]">
        <div class="[ z-[60] absolute bottom-36 end-12 ]">
            <div class="[ grid gap-4 ]">
                <TransitionGroup
                    appear
                    enter-active-class="transition duration-300 ease-bounce"
                    enter-from-class="opacity-workStart 0 scale-50"
                    enter-to-class="opacity-100 scale-100"
                    leave-active-class="transition duration-150 ease-in"
                    leave-from-class="opacity-100 scale-100"
                    leave-to-class="opacity-0 scale-95"
                >
                    <PomodoroTimer :timer="timer" @done="handlePomodoroTimerDone" v-for="timer in mutableTimers" :key="timer.id" />
                </TransitionGroup>
            </div>
        </div>

        <div class="[ z-50 absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-black/80 h-1/3 flex items-end justify-end py-8 px-12 ]">
            <div class="[ text-white text-right ]">
                <div class="[ tracking-tighter text-4xl font-[400] ]">{{ time }}</div>
                <p class="[ opacity-80 tracking-tight ]">San Juan City, Philippines</p>
            </div>
        </div>

        <div class="[ absolute inset-0 bg-black/10 z-40 ]"></div>

        <div class="[ absolute inset-0 object-center z-30 scale-120 ]">
            <img :src="image" alt="" class="[ h-full w-full object-contain ]" />
        </div>

        <div class="[ absolute inset-0 bg-black/30 z-20 ]"></div>

        <div class="[ absolute inset-0 object-center z-10 slow-zoom origin-center ]" ref="$blurredImage">
            <img :src="image" alt="" class="blurred-image [ h-full w-full object-cover blur-lg ]" />
        </div>
    </div>
</template>

<style>
.slow-zoom {
    will-change: transform;
    animation: slow-zoom 10s forwards ease-in;
}

@keyframes slow-zoom {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1.25);
    }
}
</style>
